.imgNavBar {
    height: 10%;
    width: 8%;
    margin: 10px 15% 2px 5%;
}

@media (max-width: 600px) {
    .imgNavBar {
      width: 40%;
    }
  }

  /* Arriba | Derecha | Abajo | Izquierda */