.items-container {
    background-color: #EAE7E6;
}

.items-list {
    list-style: none;
}

.items-list li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: wrap;
}

.items-list li:nth-child(odd) { background: rgb(194, 194, 194); }

.items-list-iconos a{
    text-decoration: none;
    margin-right: 5px;
    padding: 5px;
}

.items-list-iconos a::before{
    text-decoration: none;
}

.form-container {
    display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: flex-start;
	align-content: flex-start;
    
}
.form-btn {
    display: inline-block;
}

.btn-modificar {
    color: white;
    width: 150px;
    height: 45px;
    background: rgba(77, 192, 88, 0.73);
    border-radius: 30px;
}

.btn-modificar:hover {
    color: black;
    background: rgba(114, 184, 121, 0.73);
}

.container-titulo {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.container-borrar {
    display: flex;
    justify-content: flex-end;
    padding-right: 50px;
}

.btn-borrar {
    color: white;
    width: 100px;
    height: 25px;
    background: rgba(192, 85, 77, 0.73);
    border-radius: 30px;
}

.btn-borrar:hover {
    color: black;
    background: rgb(225, 80, 70);
}



.relaciones-form-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    
    align-content: center;
}

.relaciones-form-container-card {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.card-relacion {
    flex-direction: row;
    width: 200px;
    height: 120px;
    border: solid 1px black;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    margin: 3px;
}

.card-relacion-añadir {
    flex-direction: row;
    width: 200px;
    height: 120px;
    border: solid 1px black;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    margin: 3px;
}

.relaciones-nuevo-container {
    margin-bottom: 50px;
}

.relaciones-nuevo-agregados {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
}

.relaciones-nuevo-btn-eliminar {
    margin: 10px;
    color:rgb(228, 53, 53);
}


.btn-icon-quitar {
    color: rgb(228, 53, 53);
}

.btn-nuevo-item {
    color: white;
    width: 150px;
    height: 45px;
    background: rgba(77, 192, 88, 0.73);
    border-radius: 30px;
}

.detalle-container {
    
}

.detalle-items {
    display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: flex-start;
	align-content: flex-start;
}

.estado-ok {
    color: green
}

.estado-warning {
    color: greenyellow;
}

.estado-error {
    color: red
}