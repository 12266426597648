.monitor-container {
    width: auto;
    height: auto;
    color: black;
    touch-action: pan-y;
}


.monitor-box {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    
}

.monitor-text {
    align-items: flex-end;
    padding-top: 50px;
}

.monitor-text textarea {
    resize: none;
}

#text pre {
    overflow-x: auto;
  }

@media only screen and (max-width: 1200px){
    .monitor-box {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 5px;
        grid-row-gap: 5px;
    }
    
}

@media only screen and (max-width: 980px){
    .monitor-box {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 5px;
        grid-row-gap: 5px;
    }
}

@media only screen and (max-width: 680px){
    .monitor-box {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-column-gap: 5px;
        grid-row-gap: 5px;
       
    }
}